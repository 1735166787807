import React from 'react'
import { graphql, Link } from 'gatsby'
import { Back, Heading, Layout, TextImage } from '../components'
import { Col, Grid, Row } from 'react-styled-flexboxgrid'

type PrivacyPageTemplateProps = {
	title: string
}

const PrivacyPageTemplate: React.FC<PrivacyPageTemplateProps> = ({ title }) => {
	return (
		<>
			<Grid>
				<Row center={'md'}>
					<Col xs md={8}>
						<Back to={'/'} grid={false} />
						<Heading variant={'h1'} as={'h1'}>
							Privacy statement
						</Heading>
						<Heading variant={'h3'} as={'h2'}>
							Data Privacy Statement
						</Heading>
						<p>
							We value you as a customer and share your concerns about privacy of your information. We are committed to
							safeguarding the privacy of your information. We have accordingly established various (physical and
							electronic) safeguards to protect your information in a reasonable and secure way. We are providing this
							statement to explain to you how we will handle your personal information that we obtain from you.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							What do we do with your information?
						</Heading>
						<p>
							We will only collect and use your information where we have lawful grounds and legitimate business reasons
							to do so. If we have collected your information for a particular purpose, we will not use it for anything
							else unless you have been informed and, where relevant, your permission obtained. We will not sell or
							share your customer information with companies or organizations outside of our network that would use it
							to contact you about their products and services. Our network of agents and brokers may use your
							information to help you with your insurance needs. We may also contact you about products, features and
							options in which you have expressed an interest. Without your consent, we may provide your information to
							persons or organizations in and out of our network. This will however be done as permitted or required by
							law. We may do this to fulfill a transaction you have requested, market our services to you, detect or
							prevent fraud, participate in insurance support organizations (information in a report prepared by an
							insurance organization may be retained by that organization and distributed to other persons) or comply
							with lawful requests from regulatory and law enforcement authorities. We will retain information collected
							about you for as long as permitted for legitimate business purposes.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							What kind of customer information do we have and where did we get it?
						</Heading>
						<p>
							Much of the information that we have about you comes from you. When you perform certain transactions, you
							may give us information such as your name, address, e-mail address, telephone number, occupation
							information and auto/property ownership. These transactions include when you submit a request for
							insurance, submit a request for renewal, report a loss and submit request or enquiries.
						</p>
						<p>
							We also keep information relating to the products and services you purchase from us and our network.
							Examples of this include type of policy and which intermediary you used, among other information.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							How do we protect your customer information?
						</Heading>
						<p>
							We require any company with whom we share your information to use it only to provide the service we have
							asked them to deliver. We also ensure that any such company has appropriate security measures in place and
							will contractually require them to comply with these privacy principles.
						</p>
						<p>
							Information about you is also available within Insure Anytime. It is available to those people who may
							need to use it to meet and service your needs. Those persons are however required to protect your
							information. We train our team on our privacy obligations. If your relationship with us ends, your
							information will remain protected under our privacy policies outlined in this Statement. We will implement
							and adhere to information retention policies relating to your information, and will ensure that your
							information is securely disposed of at the end of the appropriate retention period.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							Whose Privacy Statement applies to Links?
						</Heading>
						<p>
							Insure Anytime may provide hypertext links to third party internet websites as a convenience to persons
							who visit the Insure Anytime web site. Where we provide such links, they are not an endorsement by us of
							any products or services in such websites. If you choose to use the services provided by those websites,
							you may be asked by those sites to provide certain personally identifiable information (some of which may,
							on an individual or aggregated basis, be shared with Insure Anytime). Please be aware that Insure Anytime
							is not responsible for the privacy practices of those websites, even though Insure Anytime’s name or logo
							may appear on those websites. We encourage you to be aware when you leave our site and to read the privacy
							policies of each and every website that you visit, as the privacy policies of those websites may differ
							from ours. Our Privacy Statement applies solely to this website.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							The right to access your personal information
						</Heading>
						<p>
							You are entitled to a copy of the personal information that Insure Anytime holds about you and certain
							details about how we use it. Your information will usually be provided to you in writing, unless otherwise
							requested, or where you have made the request by electronic means, in which case the information will be
							provided to you by electronic means where possible.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							The right to rectification
						</Heading>
						<p>
							We will take reasonable steps to ensure that the information we hold about you is accurate and complete.
							However, if you do not believe this is the case, you may ask us to update or amend it.
						</p>
						<p>In certain circumstances, you have the right to ask us to erase your personal information.</p>
						<Heading variant={'h3'} as={'h2'}>
							The right to restriction of processing
						</Heading>
						<p>
							In certain circumstances, you are entitled to ask us to stop using your personal information. Please note
							that in some circumstances exercise of this right will mean we are unable to continue providing you with
							service.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							The right to data portability
						</Heading>
						<p>
							In certain circumstances, you have the right to ask that Insure Anytime provide your personal information
							to you in a commonly used electronic format.
						</p>
						<p>The right to object to marketing</p>
						<p>You may ask Insure Anytime to stop sending you marketing messages at any time.</p>
						<Heading variant={'h3'} as={'h2'}>
							The right to withdraw consent
						</Heading>
						<p>
							For certain uses of your personal information, we will ask for your consent. Where we do this, you have
							the right to withdraw your consent to further use of your personal information. Please note that in some
							circumstances exercise of this right will mean we are unable to continue providing you with service.
						</p>
						<Heading variant={'h3'} as={'h2'}>
							The right to lodge a complaint with a data protection authority
						</Heading>
						<p>
							You have a right to complain to the data protection authority where you live or work, or in which you
							consider your complaint arose, if you believe that any use of your personal information by Insure Anytime
							is in breach of applicable data protection laws and regulations.
						</p>
						<p>Making a complaint will not affect any other legal rights or remedies that you have.</p>
					</Col>
				</Row>
			</Grid>
		</>
	)
}

type PrivacyPageProps = {
	data: {
		markdownRemark: {
			frontmatter: PrivacyPageTemplateProps
		}
	}
}

const AboutPage: React.FC<PrivacyPageProps> = ({
	data: {
		markdownRemark: { frontmatter },
	},
}) => {
	return (
		<Layout showRenewLinkOnMobile showFooterContact>
			<PrivacyPageTemplate {...frontmatter} />
		</Layout>
	)
}

const pageQuery = graphql`
	query PrivacyPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "contact-page" } }) {
			frontmatter {
				title
			}
		}
	}
`

export default AboutPage
export { PrivacyPageTemplate, pageQuery }
